import React, { useState } from 'react';
import styled from '@emotion/styled';
import CaretDownIcon from '@assets/svgs/caret-down.svg';
import Image from '~/components/atoms/Image/Image';
import { useMobile } from '~/utils/hook-functions';

type Props = { src: string; name: string; caret?: boolean };

const Person: React.FC<Props> = ({ src, name, caret, children }) => {
  const [opened, setOpened] = useState(false);
  const mobile = useMobile();

  const clickHandler = () => {
    setOpened(!opened);
  };

  const CaretOpenClose = () => {
    return opened ? (
      <CaretDownIcon
        height={mobile ? '30' : '44'}
        style={{ transform: 'rotate(180deg)' }}
      />
    ) : (
      <CaretDownIcon height={mobile ? '30' : '44'} />
    );
  };

  return (
    <>
      <Container>
        <Flex
          onClick={clickHandler}
          style={{ cursor: caret ? 'pointer' : null }}
        >
          <Image src={src} alt={name} width={150} />
          <Description>{name}</Description>
          <Caret>{caret ? <CaretOpenClose /> : null}</Caret>
        </Flex>
        <div css={{ display: opened ? 'block' : 'none' }}>{children}</div>
      </Container>
    </>
  );
};

const Container = styled.section`
  @media (min-width: 1200px) {
    max-width: 1200px;
    font-size: 1.5rem;
  }

  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 100%;
  font-size: 1rem;
  line-height: 1.5;
`;

const Flex = styled.div`
  @media (min-width: 800px) {
    height: 150px;
    justify-content: left;
  }
  display: flex;
  flex-wrap: wrap;
  background-color: var(--primary-color);
`;

const Description = styled.div`
  margin-left: 2rem;
  align-self: center;
  margin-bottom: 0.4rem;
`;

const Caret = styled.div`
  margin-left: auto;
  margin-right: 2rem;
  align-self: center;
`;

export default Person;
