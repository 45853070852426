/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import { SmallCVContainer } from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/team';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/team';

const CVSabine: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <SmallCVContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: t('CVSabine') || tEn('CVSabine'),
            }}
          />
        </SmallCVContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: white;

  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export default CVSabine;
